<template>
  <div >

    <div class="card card-bordered">
      <div class="card-header ribbon">
        <div class="card-title">基本示例</div>
        <div class="ribbon-label bg-primary">Ribbon</div>
      </div>
      <div class="card-body">
        ...
      </div>
    </div>

    <div class="d-flex">
      <div class="card card-bordered x-w-300px mr-2">
        <div class="card-header ribbon ribbon-end">
          <div class="ribbon-label bg-primary">Ribbon</div>
          <div class="card-title">圆角示例</div>
        </div>

        <div class="card-body">
          ...
        </div>
      </div>
      <!--end::Card-->

      <!--begin::Card-->
      <div class="card x-w-300px mr-2">
        <div class="card-header ribbon ribbon-top">
          <div class="ribbon-label bg-primary">Ribbon</div>
          <div class="card-title">圆角示例</div>
        </div>

        <div class="card-body">
          ...
        </div>
      </div>
      <!--end::Card-->

      <!--begin::Card-->
      <div class="card x-w-300px mr-2">
        <div class="card-header justify-content-end ribbon ribbon-start">
          <div class="ribbon-label bg-primary">Ribbon</div>
          <div class="card-title">圆角示例</div>
        </div>

        <div class="card-body">
          ...
        </div>
      </div>
    </div>

    <div class="card ">
      <div class="card-header ribbon ribbon-top ribbon-vertical">
        <div class="ribbon-label bg-danger">
          OK!
        </div>
        <div class="card-title">Ribbon Example</div>
      </div>
      <div class="card-body">
        ...
      </div>
    </div>

    <div class="card ">
      <div class="card-header ribbon ribbon-end ribbon-clip">
        <div class="ribbon-label">
          Ribbon
          <span class="ribbon-inner bg-info"></span>
        </div>
        <div class="card-title">Ribbon Example</div>
      </div>
      <div class="card-body">
        ...
      </div>
    </div>

    <div class="overflow-hidden position-relative card-rounded">
      <!--begin::Ribbon-->
      <div class="ribbon ribbon-triangle ribbon-top-start x-border-success">
        <!--begin::Ribbon icon-->
        <div class="ribbon-icon mt-n2 ml-n2">
          <span class="svg-icon svg-icon-white">
            <inline-svg src="media/icons/duotune/technology/teh001.svg" />
          </span>
        </div>
        <!--end::Ribbon icon-->
      </div>
      <!--begin::Ribbon-->
      <div class="ribbon ribbon-triangle ribbon-top-end x-border-success">
        <!--begin::Ribbon icon-->
        <div class="ribbon-icon mt-n2 mr-n2">
          <span class="svg-icon svg-icon-white">
            <inline-svg src="media/icons/duotune/technology/teh001.svg" />
          </span>
        </div>
        <!--end::Ribbon icon-->
      </div>
      <!--end::Ribbon-->

      <!--begin::Card-->
      <div class="card card-bordered">
        <!--begin::Header-->
        <div class="card-header ribbon ribbon-top ribbon-vertical">
          <div class="card-title">Ribbon Example2</div>
        </div>
        <!--end::Header-->

        <!--begin::Body-->
        <div class="card-body">
          ...
        </div>
        <!--end::Body-->
      </div>
      <!--end::Card-->
    </div>

  </div>
</template>

<script>

export default {
  name: 'example',
}
</script>

<style scoped>

</style>
